// Imports
  @import 'modules/_fonts';
  @import 'modules/_all';
// END Imports

//Side menu
.dd-nav-menu {
  font-size: 12px;
  text-transform: uppercase;
  font-family: $Raleway-Regular;

  @media screen and (max-width: 750px) {
    display: none;
  }

  li {
    border: 1px solid $gray;
    margin-bottom: 5px;
    @include fade;

    &.dd-sm-active,
    &:hover {
      border: 1px solid $blue;

      a {
        color: $blue;
      }
    }
  }

  a {
    padding: 11px 13px;
    color: $dark-gray-w1;
    display: block;
  }
}
//end Side menu

//Select menu side
.dd-select-nav-menu {
  display: none;

  @media screen and (max-width: 750px) {
    display: block;
  }

  select {
    font-size: 12px;
    border: 1px solid $blue;
    text-transform: uppercase;
    color: $blue;
    padding: 11px 13px;
  }

  .fa-chevron-down {
    font-size: 22px;
    color: $blue;
    right: 11px;
    top: 9px;
    pointer-events: none;
  }
}
//end Select menu side

//Structure
.dd-main-content {
  font-size: 14px;

  &.dd-wsidebar {
    width: 66%;
    vertical-align: top;

    @media screen and (max-width: 750px) {
      width: 100%;
    }
  }
}

.dd-bside {
  width: 34%;
  vertical-align: top;
  font-size: 14px;

  @media screen and (max-width: 750px) {
    width: 100%;
    margin-bottom: 33px;
  }

  &.dd-bside-left {
    padding-right: 62px;

    @media screen and (max-width: 750px) {
      padding-right: 0;
    }
  }

  &.dd-bside-right {
    padding-left: 56px;

    @media screen and (max-width: 750px) {
      display: none;
    }
  }

  section {
    margin-bottom: 56px;
  }
}
//end Structure

//Date
.dd-date {
  background-color: $blue;
  color: $white;
  position: absolute;
  text-align: center;

  .dd-day {
    font-family: $OpenSans-Semibold;
    font-size: 22px;
    display: block;
  }

  .dd-month {
    font-size: 14px;
    display: block;
  }
}
//end Date


//Buttons
.dd-btns-options {
  .dd-btn {
    text-transform: uppercase;
    text-align: center;
  }

  .dd-btn-author {
    background-color: $light-gray;
    font-size: 11px;
    padding: 16px 18px;

    .fa {
      font-size: 15px;
      margin-right: 5px;
    }
  }

  .dd-btn-more {
    background-color: $yellow;
    font-size: 13px;
    padding: 15px 36px;

    .fa {
      margin-right: 13px;
    }

    &:hover {
      background-color: $blue;
      color: $white;
    }
  }
}
//end Buttons

.dd-title-icon {
  @media screen and (max-width: $mobile) {
    margin: 0 auto;
    display: block;
    text-align: center;
  }
}

.dd-title-service {
  @include calc('width', '100% - 88px');
  font-size: 32px;

  @media screen and (max-width: $mobile) {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
}
